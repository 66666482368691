import styled from 'styled-components'
import * as T from '../Typography'

interface IProps {
  color: string;
  large: boolean;
}

export const Blog = styled.div``

export const Post = styled.article`
  &:not(:first-child) {
    margin-top: var(--x4-space);
  }

  @media (min-width: ${props => props.theme.tabletBreak}) {
    width: ${(props: IProps) => (props.large) ? `100%` : `75%`};
  }
`

export const BackgroundImage = styled.div``

export const Box = styled.div`
  min-height: 300px;
  padding: var(--x5-space) var(--x3-space) var(--x3-space);
  background-color: transparent;
  transition: background-color .125s linear;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  @media (min-width: ${props => props.theme.tabletBreak}) {
    &:hover {
      background-color: var(--yellow);
    }
  }
`

export const Content = styled.div`
  margin-top: var(--x4-space);
`

export const Title = styled.div`
  color: ${(props: IProps) => props.color};
`

export const Meta = styled.div`
  margin-top: var(--x1-space);
`

export const Description = styled(T.Label)`
  display: inline;
  font-family: var(--font-mark-pro-medium);
  color: ${(props: IProps) => props.color};
`

export const Date = styled(T.Label)`
  display: inline;
  color: var(--grey);
  margin-left: var(--x1-space);
`

export const Button = styled.div`
  margin-top: var(--x4-space);
`
