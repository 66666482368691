import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import BackgroundImage from 'gatsby-background-image';
import CTA from '../CTA';
import * as T from '../Typography';
import * as S from './styles';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  pageMode?: boolean;
  maxPosts?: number;
  label: string;
  edges: Array<{
    node: {
      fields: {
        slug: string;
      };
      frontmatter: {
        large?: boolean;
        title?: string;
        date?: string;
        description?: string;
        image?: any;
      };
    };
  }>;
}

const defaultProps = {
  pageMode: false,
  maxPosts: 1
};

const Post = props => {
  const [hover, setHover] = React.useState(false);
  const node = props.post.node;
  const frontmatter = node.frontmatter;

  return (
    <S.Post large={!props.isMobile && frontmatter.large}>
      <CTA type="link" to={node.fields.slug} display={'block'}>
        <S.BackgroundImage>
          <BackgroundImage
            Tag="div"
            fluid={frontmatter.image && frontmatter.image.childImageSharp.fluid}
            className="backgroundImage"
            backgroundColor="var(--grey)"
            classId=""
          >
            <S.Box
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <S.Title
                as={frontmatter.large ? T.H4 : T.HugeBodyCopy}
                renderers={{root: 'h2'}}
                disallowedTypes={['paragraph']}
                unwrapDisallowed={true}
                color={hover ? 'var(--black)' : 'var(--white)'}
              >
                {frontmatter.title}
              </S.Title>
              <S.Meta>
                <S.Description color={hover ? 'var(--black)' : 'var(--white)'}>
                  {frontmatter.description}
                </S.Description>
                <S.Date>{frontmatter.date}</S.Date>
              </S.Meta>
            </S.Box>
          </BackgroundImage>
        </S.BackgroundImage>
      </CTA>
    </S.Post>
  );
};

const Blog = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);
  const [maxPosts, setMaxPosts] = React.useState(props.maxPosts || 1); // TODO Anti-pattern?

  return (
    <S.Blog>
      <Grid columns={12}>
        <Cell width={12} left={1}>
          {props.edges &&
            props.edges
              .slice(0, maxPosts)
              .map((edge, index) => (
                <Post key={index} post={edge} isMobile={isMobile} {...props} />
              ))}
          {props.pageMode && (
            <S.Button>
              <CTA
                to="#"
                type="button"
                onClick={() => setMaxPosts(maxPosts + 4)}
                title="Load more"
              >
                Load more
              </CTA>
            </S.Button>
          )}
        </Cell>
      </Grid>
    </S.Blog>
  );
};

Blog.defaultProps = defaultProps;

export default withBreakpoints(Blog);
