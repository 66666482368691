import * as React from 'react';
import * as S from './styles';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export interface IProps {
  pageMode?: boolean;
  maxTweets?: number;
}

const defaultProps = {
  pageMode: false,
  maxTweets: 1
};

const Twitter = (props: IProps) => {
  const configProfile = {
    profile: { screenName: 'beyonce' },
    domId: 'twitter',
    maxTweets: props.maxTweets,
    enableLinks: true,
    showUser: false,
    showTime: true,
    showImages: false,
    lang: 'en',
    dateFunction: date => {
      return `${date.getDate()} ${
        monthNames[date.getMonth()]
      }, ${date.getYear()}`;
    },
    showInteraction: false
  };

  React.useEffect(() => {
    const twitterFetcher = require('twitter-fetcher');

    twitterFetcher.fetch(configProfile);
  });

  return (
    <S.Twitter pageMode={props.pageMode}>
      {props.pageMode && (
        <S.TwitterHeader>Zero 1 on Twitter</S.TwitterHeader>
      )}
      <S.TwitterFetcher>
        <div id="twitter" />
      </S.TwitterFetcher>
    </S.Twitter>
  );
}

Twitter.defaultProps = defaultProps;

export default Twitter;
