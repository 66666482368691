import styled from 'styled-components';
import * as T from '../Typography';
import Tweet from '../../images/bgTweet.svg';

interface IProps {
  pageMode: boolean;
}

export const Twitter = styled.div`
  border-top: ${(props: IProps) => (props.pageMode) ? `none` : `1px solid var(--borderGrey)`};

  @media (min-width: ${props => props.theme.tabletBreak}) {
    margin-top: 0;
  }

  /* stylelint-disable */
  #twitter {
    > ul {
      list-style: none;

      > li {
        &:not(:first-child) {
          margin-top: var(--x4-space);
          padding-top: var(--x1-space);
          border-top: 1px solid var(--borderGrey);
        }
      }
    }

    .tweet {
      font: 400 var(--type-9) var(--font-mark-pro-book);

      a {
        color: inherit;
      }
    }

    .timePosted {
      margin-top: var(--x1-space);
      color: var(--grey);
      font: 400 var(--type-7) var(--font-mark-pro-bold);
      letter-spacing: 3px;
      text-transform: uppercase;
      background-image: url(${Tweet});
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: var(--x2-space);

      a {
        color: inherit;
      }
    }
  }
  /* stylelint-enable */
`;

export const TwitterHeader = styled(T.HugeBodyCopy)`
  margin-top: var(--x8-space);
  padding-left: var(--x4-space);
  margin-bottom: var(--x4-space);
  white-space: nowrap;
  position: relative;

  &::before {
    position: absolute;
    left: 0;
    top: calc(-1 * var(--xhalf-space));
    content: "Z1";
    vertical-align: middle;
    color: var(--black);
    background-color: var(--yellow);
    width: 48px;
    height: 48px;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
  }
`;

export const TwitterFetcher = styled.div`
  margin-top: var(--x1-space);

  & .timeline-Tweet-author {
    display: none;
  }
`;
