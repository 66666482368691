import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import News from '../components/News';
import Background from '../components/Background';
import Head from '../components/Head';

const NewsPage = ({ data }) => {
  const news = data.news.frontmatter;

  return (
    <Layout>
      <Background />
      <Head title={news.title} description={news.description} />
      <Header {...news.header} />
      <News pageMode={true} maxPosts={4} maxTweets={3} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query SiteNewsPageQuery {
    news: markdownRemark(frontmatter: { templateKey: { eq: "news" } }) {
      frontmatter {
        title
        description
        header {
          headline
          straplines
          address
        }
      }
    }
  }
`;

export default NewsPage;
