import styled from 'styled-components'
import { Section }  from '../Layout/styles'
import * as T from '../Typography'

export const News = styled(Section)``

export const Label = styled(T.Label)`
  margin-bottom: var(--x4-space)
`

export const Button = styled.div`
  margin-top: var(--x4-space);
`
