import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Blog from '../components/Blog'

export default (props) => {
  // TODO improve on this... https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
  return (<StaticQuery
    query={graphql`
      query SiteBlogQuery {
        blog: allMarkdownRemark (
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              date: { ne: null }
            }
          }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                large
                title
                date(formatString: "MMMM DD, YYYY")
                description
                image {
                  childImageSharp {
                    fluid(quality: 85, maxWidth: 960) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Blog {...data.blog} {...props} />}
  />)
}
