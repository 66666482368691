import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import CTA from '../CTA';
import Blog from '../../containers/Blog';
import Twitter from '../Twitter';
import * as S from './styles';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  label: string;
  pageMode: boolean;
  maxPosts: number;
  maxTweets: number;
}

const defaultProps = {
  pageMode: false,
  maxPosts: 1,
  maxTweets: 1
};

const News = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);

  return (
    <S.News>
      <Grid columns={24}>
        <Cell width={22} left={2}>
          <Grid columns={12}>
            <Cell width={12} left={isMobile ? 1 : 3}>
              {!props.pageMode && (
                <S.Label color="var(--white)" hasLine={true}>Zero 1 in the news</S.Label>
              )}
            </Cell>
            <Cell width={isMobile ? 12 : 6} left={isMobile ? 1 : 2}>
              <Blog maxPosts={props.maxPosts} pageMode={props.pageMode} />
            </Cell>
            <Cell width={isMobile ? 12 : 3} left={isMobile ? 1 : 9}>
              <Twitter maxTweets={props.maxTweets} pageMode={props.pageMode} />
            </Cell>
            <Cell width={12} left={isMobile ? 1 : 3}>
              <S.Button>
                {!props.pageMode && (
                  <CTA type="button" to="/news" title="Read more">
                    Read more
                  </CTA>
                )}
              </S.Button>
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </S.News>
  );
};

News.defaultProps = defaultProps;

export default withBreakpoints(News);
